// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "channels"
import '@fortawesome/fontawesome-free/js/all';
import Chart from 'chart.js/auto';
import 'bootstrap'; // Import Bootstrap after jQuery
import "@hotwired/turbo-rails"

global.Chart = Chart;

//= require bootstrap-sprockets
require("@nathanvda/cocoon")
require('admin-lte')
require('jquery-number')
require('datatables.net-bs4')
require('packs/datepicker')
require('packs/datepicker-hr')
require('packs/jquery-ui.min')
require('packs/select2.min')
require('packs/clone_js_fix')
require('packs/general')
require('packs/ajax_data_fetch')
require('packs/charts')
require('packs/workout_plan')
require('packs/workout_create')
require('packs/subscriptions')
require('packs/images')
require('packs/dropzone.min')
require('packs/dropzone_config')
require('packs/compression')

Rails.start()