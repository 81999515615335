function measurementsChart() {
    measurementsChartObj = $("#measurements-chart");

    // return unless Chart object for measurements exists
    if(measurementsChartObj.length == 0) {
        return;
    }


    // Get the chart instance associated with the canvas
    const chartInstance = Chart.getChart(measurementsChartObj[0]); // Get the chart instance

    // If a chart instance exists, destroy it - because it can cause issues
    if (chartInstance) {
        chartInstance.destroy(); // Destroy the existing instance
    }

    weight = {
        label: "Masa",
        data: measurementsChartObj.data('data').weight,
        fill: false,
        lineTension: 0,
        borderColor: '#000000',
        borderWidth: 3
    };

    waist = {
        label: "Struk",
        data: measurementsChartObj.data('data').waist,
        fill: false,
        lineTension: 0,
        borderColor: '#ff1616',
        borderWidth: 3
    };

    biceps = {
        label: "Nadlaktica",
        data: measurementsChartObj.data('data').biceps,
        fill: false,
        lineTension: 0,
        borderColor: '#D4C4F1'
    };

    biceps_flex = {
        label: "Nadlaktica (kontrakcija)",
        data: measurementsChartObj.data('data').biceps_flex,
        fill: false,
        lineTension: 0,
        borderColor: '#F1C4EA'
    };

    hips = {
        label: "Bokovi",
        data: measurementsChartObj.data('data').hips,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1D0'
    };

    chest = {
        label: "Prsa",
        data: measurementsChartObj.data('data').chest,
        fill: false,
        lineTension: 0,
        borderColor: '#C4F1F1'
    };

    thigh = {
        label: "Bedro",
        data: measurementsChartObj.data('data').thigh,
        fill: false,
        lineTension: 0,
        borderColor: '#F1D6C4'
    };

    measurementData = {
        labels: measurementsChartObj.data('data').labels,
        datasets: [weight, waist, chest, hips, thigh, biceps, biceps_flex]
    };

    chartOptions = {
        legend: {
            display: true,
            position: 'top',
            labels: {
                boxWidth: 80,
                fontColor: 'black',
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true
    };

    lineChart = new Chart(measurementsChartObj, {
        type: 'line',
        data: measurementData,
        options: chartOptions
    });
}

function statistics_money_chart() {
    moneyChartObj = $("#statistics-money-chart");

    // return unless Chart object for measurements exists
    if (moneyChartObj.length == 0) {
        return;
    }

    agreed = {
        label: "Ugovoreno",
        data: moneyChartObj.data('data').subscriptions_total,
        fill: false,
        lineTension: 0,
        backgroundColor: '#007bff',
        borderWidth: 3
    };

    paid = {
        label: "Naplaćeno",
        data: moneyChartObj.data('data').paid_total,
        fill: false,
        lineTension: 0,
        backgroundColor: '#28a745',
        borderWidth: 3
    };

    measurementData = {
        labels: moneyChartObj.data('data').labels,
        datasets: [agreed, paid]
    };

    chartOptions = {
        legend: {
            display: true,
            position: 'top',
            labels: {
                boxWidth: 80,
                fontColor: 'black',
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    lineChart = new Chart(moneyChartObj, {
        type: 'bar',
        data: measurementData,
        options: chartOptions
    });
}

function statistics_money_chart_sum() {
    moneySumChartObj = $("#statistics-money-chart-sum");

    // return unless Chart object for measurements exists
    if (moneyChartObj.length == 0) {
        return;
    }

    lineChart = new Chart(moneySumChartObj, {
        type: 'pie',
        data: {
            labels: moneySumChartObj.data('data').labels,
            datasets: [{
                backgroundColor: [
                    "#28a745",
                    "#007bff",
                ],
                data: moneySumChartObj.data('data').values
            }]
        },

    });
}


$(document).on( "turbo:load", function() {
    if ($('#memberOverviewModal').length === 0) {
        measurementsChart(); // Initialize chart for non modal places
    }
    statistics_money_chart();
    statistics_money_chart_sum();
});

$(document).on("turbo:frame-load", function () {
    $('#memberOverviewModal').on('shown.bs.modal', function () {
        measurementsChart();
    });
});






